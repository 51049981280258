(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('FlashSalePriceDialogController', FlashSalePriceDialogController);

    FlashSalePriceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'productName', 'discountPercentage', 'customerType', 'variants'];

    function FlashSalePriceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, productName, discountPercentage, customerType, variants) {
        var vm = this;

        vm.productName = productName;
        vm.discountPercentage = discountPercentage;
        vm.customerType = customerType;
        vm.variants = variants;
        vm.calculateSalePrice = calculateSalePrice;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function calculateSalePrice() {
            if (vm.variants.retailPrice && vm.discountPercentage) {
                return vm.variants.retailPrice - (vm.variants.retailPrice * (vm.discountPercentage / 100));
            } else {
                return 0;
            }
        }

        vm.close = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
