(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('flash-sale', {
                parent: 'entity',
                url: '/flash-sale?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.flashSale.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/flash-sale/flash-sales.html',
                        controller: 'FlashSaleController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'endTime,asc',
                        squash: true
                    },
                    field: null,
                    sku: null,
                    category: null,
                    statuses: ["AVAILABLE", "OUT_OF_STOCK"],
                    flashSaleStatus: "UNKNOWN",
                    customerType: "UNKNOWN",
                    parentCategoryField: null,
                    childCategoryField: null,
                    parentCategories: null,
                    childCategories: null,
                    childCategoryList: []

                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            field: $stateParams.field,
                            sku: $stateParams.sku,
                            category: $stateParams.category,
                            statuses: $stateParams.statuses,
                            flashSaleStatus: $stateParams.flashSaleStatus,
                            customerType: $stateParams.customerType,
                            parentCategoryField: $stateParams.parentCategoryField,
                            childCategoryField: $stateParams.childCategoryField,
                            parentCategories: $stateParams.parentCategories,
                            childCategories: $stateParams.childCategories,
                            childCategoryList: $stateParams.childCategoryList,
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('flashSale');
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('flashSaleStatus');
                        $translatePartialLoader.addPart('visibleState');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('flash-sale-detail', {
                parent: 'flash-sale',
                url: '/flash-sale/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.flashSale.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/flash-sale/flash-sale-detail.html',
                        controller: 'FlashSaleDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('flashSale');
                        $translatePartialLoader.addPart('flashSaleStatus');
                        $translatePartialLoader.addPart('visibleState');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'FlashSale', function ($stateParams, FlashSale) {
                        return FlashSale.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'flash-sale',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('flash-sale-detail.edit', {
                parent: 'flash-sale-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/flash-sale/flash-sale-dialog.html',
                        controller: 'FlashSaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['FlashSale', function (FlashSale) {
                                return FlashSale.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('flash-sale.new', {
                parent: 'flash-sale',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/flash-sale/flash-sale-dialog.html',
                        controller: 'FlashSaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    startTime: null,
                                    endTime: null,
                                    discountPercentage: null,
                                    status: null,
                                    visibleState: null,
                                    createdBy: null,
                                    createdDate: null,
                                    lastModifiedBy: null,
                                    lastModifiedDate: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('flash-sale', null, {reload: 'flash-sale'});
                    }, function () {
                        $state.go('flash-sale');
                    });
                }]
            })
            .state('flash-sale.edit', {
                parent: 'flash-sale',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/flash-sale/flash-sale-dialog.html',
                        controller: 'FlashSaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['FlashSale', function (FlashSale) {
                                return FlashSale.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('flash-sale', null, {reload: 'flash-sale'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('flash-sale.delete', {
                parent: 'flash-sale',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/flash-sale/flash-sale-delete-dialog.html',
                        controller: 'FlashSaleDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['FlashSale', function (FlashSale) {
                                return FlashSale.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('flash-sale', null, {reload: 'flash-sale'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('flash-sale-history', {
                parent: 'entity',
                url: '/flash-sales/{id}/flash-sale-history?page&sort&search',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SELLER', 'ROLE_MARKET_MANAGER', 'ROLE_WAREHOUSE_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.flashSale.history'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/flash-sale/flash-sale-histories.html',
                        controller: 'FlashSaleHistoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    id: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            id: $stateParams.id
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('flashSale');
                        $translatePartialLoader.addPart('flashSaleStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('product-variant-flash-sale-history', {
                parent: 'entity',
                url: '/products/{id}/product-variants/{variantId}/flash-sale-history?page&sort&search&name&variantName&sku',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SELLER', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.flashSale.history'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/flash-sale/flash-sale-histories.html',
                        controller: 'FlashSaleHistoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    valueLong1: null,
                    fromDate: null,
                    toDate: null,
                    name: "",
                    variantName: "",
                    sku: ""
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            valueLong1: $stateParams.variantId,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            name: $stateParams.name,
                            variantName: $stateParams.variantName,
                            sku: $stateParams.sku
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('flashSale');
                        $translatePartialLoader.addPart('flashSaleStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('flash-sale-products', {
                parent: 'flash-sale',
                url: '/products',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER']
                },
                onEnter: ['$state', '$uibModal', function ($state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/flash-sale/flash-sale-products-dialog.html',
                        controller: 'FlashSaleProductsDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        scrollable: true,
                        size: 'lg',
                        params: {
                            productName: null,
                            sku: null,
                            category: null,
                            statuses: ["AVAILABLE", "OUT_OF_STOCK"],
                            parentCategoryField: null,
                            childCategoryField: null,
                            parentCategories: null,
                            childCategories: null,
                            childCategoryList: []
                        },
                        resolve: {
                            pagingParams: [function () {
                                return {
                                    productName: null,
                                    sku: null,
                                    category: null,
                                    statuses: ["AVAILABLE", "OUT_OF_STOCK"],
                                    parentCategoryField: null,
                                    childCategoryField: null,
                                    parentCategories: null,
                                    childCategories: null,
                                    childCategoryList: [],
                                    search: null
                                };
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('flashSale');
                                $translatePartialLoader.addPart('product');
                                $translatePartialLoader.addPart('flashSaleStatus');
                                $translatePartialLoader.addPart('visibleState');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
    }

})();
