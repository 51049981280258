(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('FlashSaleController', FlashSaleController);

    FlashSaleController.$inject = ['$timeout', '$state', '$filter', '$translate', '$uibModal', '$q', '$scope', '$compile', 'FlashSale', 'ParseLinks', 'AlertService', 'DateUtils', 'paginationConstants', 'pagingParams', 'Category', 'ProductFlashSale', 'DepoService'];

    function FlashSaleController($timeout, $state, $filter, $translate, $uibModal, $q, $scope, $compile, FlashSale, ParseLinks, AlertService, DateUtils, paginationConstants, pagingParams, Category, ProductFlashSale, DepoService) {

        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.productStatuses = ["AVAILABLE", "OUT_OF_STOCK", "DISABLED"];
        vm.statuses = ["ACTIVE", "INACTIVE", "UPCOMING", "COMPLETED"];
        vm.parentCategoryField = pagingParams.parentCategoryField;
        vm.childCategoryField = pagingParams.childCategoryField;
        vm.childCategoryList = pagingParams.childCategoryList;
        vm.searchQuery = {
            field: pagingParams.field,
            sku: pagingParams.sku,
            category: pagingParams.category,
            statuses: pagingParams.statuses,
            flashSaleStatus: pagingParams.flashSaleStatus,
            customerType: pagingParams.customerType,
            parentCategories: pagingParams.parentCategories,
            childCategories: pagingParams.childCategories
        };
        vm.parentCategoryField = null;
        vm.onSelectCategory = onSelectCategory;
        vm.categories = [];
        vm.categoryTree = [];
        vm.open = open;
        vm.opened = {};
        vm.isEditing = false;
        vm.openDatepicker = openDatepicker;
        vm.cancelEditing = cancelEditing;
        vm.saveStatus = saveStatus;
        vm.dateDiff = DateUtils.flashSaleDateDifference;
        vm.openEditDialog = openEditDialog;
        vm.openPopover = openPopover;

        vm.search = search;
        vm.clear = clear;
        vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };
        vm.variants = [];

        function openEditDialog(flashSale) {
            var uniqueVariants = [];

            angular.forEach(vm.flashSales, function(item) {
                if (item.productId === flashSale.productId) {
                    var exists = uniqueVariants.some(function(v) {
                        return v.variantId === item.variantId;
                    });
                    if (!exists) {
                        uniqueVariants.push({
                            variantId: item.variantId,
                            variantName: item.variantName,
                            retailPrice: item.retailPrice,
                            b2bAccess: false,
                            b2bPrices: []
                        });
                    }
                }
            });

            var variantPromises = uniqueVariants.map(function(variant) {
                return DepoService.query({ productId: flashSale.productId, variantId: variant.variantId }).$promise
                    .then(function(data) {
                        variant.b2bPrices = data.length > 0 ? data : [];
                        variant.b2bAccess = data.length > 0;
                        return variant;
                    });
            });

            $q.all(variantPromises).then(function(variants) {
                $uibModal.open({
                    templateUrl: 'app/entities/flash-sale/flash-sale-dialog.html',
                    controller: 'FlashSaleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        productName: function() {
                            return flashSale.productName;
                        },
                        variants: function() {
                            return variants;
                        },
                        retailPrice: function() {
                            return flashSale.retailPrice;
                        },
                        discountPercentage: function() {
                            return flashSale.discountPercentage;
                        },
                        salePrice: function() {
                            return flashSale.salePrice;
                        },
                        startTime: function() {
                            return flashSale.startTime;
                        },
                        endTime: function() {
                            return flashSale.endTime;
                        },
                        entity: ['FlashSale', function(FlashSale) {
                            return FlashSale.get({ id: flashSale.id }).$promise;
                        }]
                    }
                }).result.then(function() {
                    loadAll();
                });
            });
        }

        vm.statusFilter = function(flashSale) {
            return function(status) {
                if (flashSale.status === 'ACTIVE') {
                    return status !== 'COMPLETED' && status !== 'UPCOMING';
                } else if (flashSale.status === 'UPCOMING') {
                    return status !== 'COMPLETED' && status !== 'ACTIVE';
                } else if (flashSale.status === 'COMPLETED') {
                    return status !== 'UPCOMING' && status !== 'ACTIVE';
                }
                return true;
            };
        };

        loadCategories();

        function search() {
            setCategory();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'endTime';
            vm.reverse = true;
            if (vm.searchQuery.sku !== null) {
                vm.predicate = 'endTime';
            }
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'endTime';
            vm.reverse = true;
            vm.parentCategoryField = null;
            vm.childCategoryField = null;
            vm.searchQuery = {
                field: null,
                sku: null,
                category: null,
                parentCategories: null,
                childCategories: null,
                statuses: ["AVAILABLE", "OUT_OF_STOCK"],
                flashSaleStatus: "UNKNOWN",
                customerType: "UNKNOWN"
            };
            vm.childCategoryList = null;
            vm.transition();
        }

        function setCategory() {
            if (vm.parentCategoryField !== null) {
                vm.searchQuery.parentCategories = [];
                vm.parentCategoryField.forEach(function (category) {
                    category = angular.fromJson(category.toString());
                    vm.searchQuery.parentCategories.push(category.id);
                });
            }
            if (vm.childCategoryField !== null) {
                vm.searchQuery.childCategories = [];
                vm.childCategoryField.forEach(function (category) {
                    category = angular.fromJson(category.toString());
                    vm.searchQuery.childCategories.push(category.id);
                });
            }
        }

        function onSelectCategory() {
            var newChildCategoryList = [];
            vm.parentCategoryField.forEach(function (element) {
                var category = angular.fromJson(element.toString());
                newChildCategoryList.push.apply(newChildCategoryList, category.children);
            });
            if (vm.childCategoryList !== null && newChildCategoryList.length < vm.childCategoryList.length && vm.childCategoryField !== null) {
                vm.childCategoryList = vm.childCategoryList.filter(function(element) {
                    return !newChildCategoryList.some(function (child){ return (child.id === element.id);});
                });
                vm.childCategoryField = vm.childCategoryField.filter(function(element) {
                    element = angular.fromJson(element.toString());
                    return !vm.childCategoryList.some(function (child){ return (child.id === element.id);});
                });
            }
            vm.childCategoryList = newChildCategoryList;
            $timeout(function () {
                angular.element('#field_child_category').multiselect(
                    'setOptions',
                    {
                        selectAllText: all,
                        nonSelectedText: noneSelected,
                        allSelectedText: allSelected
                    });
                angular.element('#field_child_category').multiselect('rebuild');
            }, 0);
        }
        loadAll();

        function loadAll () {
            ProductFlashSale.query({
                field: vm.searchQuery.field === null ? '' : vm.searchQuery.field,
                sku: vm.searchQuery.sku === null ? '' : vm.searchQuery.sku,
                category: vm.searchQuery.category,
                statuses: vm.searchQuery.statuses,
                flashSaleStatus: vm.searchQuery.flashSaleStatus,
                customerType: vm.searchQuery.customerType,
                parentCategories: vm.searchQuery.parentCategories,
                childCategories: vm.searchQuery.childCategories,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.flashSales = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field === null ? '' : vm.searchQuery.field,
                sku: vm.searchQuery.sku === null ? '' : vm.searchQuery.sku,
                category: vm.searchQuery.category,
                statuses: vm.searchQuery.statuses,
                flashSaleStatus: vm.searchQuery.flashSaleStatus,
                customerType: vm.searchQuery.customerType,
                parentCategoryField: vm.parentCategoryField,
                childCategoryField: vm.childCategoryField,
                parentCategories: vm.searchQuery.parentCategories,
                childCategories: vm.searchQuery.childCategories,
                childCategoryList: vm.childCategoryList
            });
        }

        function loadCategories() {
            Category.getAllCategories({}, function (data) {
                vm.categories = data;
                translateCategoryName();
                vm.categories.sort(function (a, b) {
                    return a.parentId > b.parentId ? 1 : -1;
                });
                for (var j = 0; j < vm.categories.length; j++) {
                    if (vm.categories[j].parentId === null) {
                        vm.categoryTree.push(vm.categories[j]);
                    } else {
                        var index = vm.categoryTree.findIndex(function (a) {
                            return a.id === vm.categories[j].parentId;
                        });
                        if (index > -1) {
                            if (angular.isUndefined(vm.categoryTree[index].children)) {
                                vm.categoryTree[index].children = [];
                            }
                            vm.categoryTree[index].children.push(vm.categories[j]);
                        }

                    }
                }
                $timeout(function () {
                    angular.element('#field_parent_category').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    angular.element('#field_parent_category').multiselect('rebuild');
                }, 0);
            });
        }

        function translateCategoryName() {
            var currentLang = $translate.use();
            for (var i = 0; i < vm.categories.length; i++) {
                if (currentLang === 'en') {
                    vm.categories[i]['name'] = vm.categories[i]['nameEng'];
                } else {
                    vm.categories[i]['name'] = vm.categories[i]['nameUzb'];
                }
            }
        }

        function open($event, elementOpened){
            $event.preventDefault();
            $event.stopPropagation();

            vm.opened[elementOpened] = !vm.opened[elementOpened];
        }

        function openDatepicker(event) {
            event.preventDefault();
            event.stopPropagation();
            vm.isDateOpen = true;
        }

        function cancelEditing() {
            vm.isEditing = false;
        }

        function saveStatus(flashSale) {
            var productFlashSale = {
                "startTime": flashSale.startTime,
                "endTime": flashSale.endTime,
                "discountPercentage": flashSale.discountPercentage,
                "status": flashSale.status,
                "visibleState": "ACTIVE",
                "createdBy": flashSale.createdBy,
                "createdDate": flashSale.createdDate,
                "lastModifiedBy": flashSale.lastModifiedBy,
                "lastModifiedDate": flashSale.lastModifiedDate,
                "productId": flashSale.productId,
                "id": flashSale.id
            };
            if(flashSale.startTime !== null && flashSale.endTime !== null && flashSale.discountPercentage !== null){
                FlashSale.update(productFlashSale, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            loadAll();
        }

        function onSaveError() {
            loadAll();
        }

        $timeout(function () {
            translateMultiSelect();

            angular.element('#productStatus').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected')
            });

            angular.element('#field_parent_category').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected'),
                maxHeight: 300
            });

            angular.element('#field_child_category').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected'),
                maxHeight: 300
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('product.all');
            noneSelected = translate('product.noneSelected');
            allSelected = translate('product.allSelected');
        }

        function openPopover($event, flashSale) {
            var elementId = flashSale.id + '-' + (flashSale.variantId ? flashSale.variantId : 'no-variant');

            var variantData = {
                variantId: flashSale.variantId,
                variantName: flashSale.variantName || '',
                retailPrice: flashSale.retailPrice,
                b2bAccess: false,
                b2bPrices: []
            };

            const queryParams = {productId: flashSale.productId};
            if (flashSale.variantId) {
                queryParams.variantId = flashSale.variantId;
            }

            DepoService.query(queryParams).$promise
                .then(function (data) {
                    variantData.b2bPrices = data.length > 0 ? data : [];
                    variantData.b2bAccess = data.length > 0;

                    var popoverContent =
                        '<div style="background: linear-gradient(135deg, #f0f4ff, #e0e7ff); padding: 15px; border-radius: 8px; box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); width: 250px; border: 1px solid #d1d5db;">' +
                        '<div style="margin-bottom: 10px;">' +
                        variantData.b2bPrices.map(function (price, index, array) {
                            return (
                                '<div style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 10px;">' +
                                '<span style="color: #374151; font-weight: bold;">' + price.tierName + ':</span>' +
                                '<div>' +
                                '<span style="color: #9ca3af; font-size: 12px; text-decoration: line-through; margin-right: 5px;">₩ ' + price.price + '</span>' +
                                '<span style="color: #d73f57; font-weight: bold; font-size: 14px;">₩ ' + ((price.price * (100 - flashSale.discountPercentage)) / 100) + '</span>' +
                                '</div>' +
                                '</div>' +
                                (index < array.length - 1 ? '<hr style="border-top: 1px solid #d1d5db; margin: 5px 0;">' : '')
                            );
                        }).join('') +
                        '</div></div>';

                        angular.element('#' + elementId).popover({
                        html: true,
                        content: popoverContent,
                        placement: 'bottom',
                        trigger: 'manual'
                    }).popover('toggle');

                    angular.element('[data-toggle="popover"]').not('#' + elementId).popover('hide');

                    angular.element(document).on('mousedown.popoverClose', function (e) {
                        if (!angular.element(e.target).closest('.popover').length && !angular.element(e.target).is('#' + elementId)) {
                            angular.element('#' + elementId).popover('hide');
                            angular.element(document).off('mousedown.popoverClose');
                        }
                    });
                });
        }
    }
})();
